import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getBackgroundColorByTimeOfDay } from '../../helpers/EntriesHelper';
import PrevMobile from '../PrevMobile';
import '../css/flowentry_detail.css';
import FlowentryDetailView from './FlowentryDetailView';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function FlowDetailsPage(props) {
    const location = useLocation();

    if (!location.state) {
        throw (`Flow details not found in ${location}`);
    }

    const [pageBackground, setPageBackground] = useState();

    useEffect(() => {
        setPageBackground({
            background: getBackgroundColorByTimeOfDay(props.timeOfDay)
        });
    }, [props.timeOfDay])

    const history = useHistory();
    const handlePrevClick = () => {
        history.goBack();
    }

    return (
        <div className='checkin-details-page' style={pageBackground}>
            <FlowentryDetailView flowEntry={location.state.entry} timeOfDay={props.timeOfDay} />
            <div className='checkin-details-back-btn-div'>
                <PrevMobile visible={true} onClick={handlePrevClick} />
            </div>
        </div>
    )
}

export default FlowDetailsPage;