import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import FlowentryDetailView from '../myCheckins/FlowentryDetailView';
import { bindKeyboard } from 'react-swipeable-views-utils';
import '../css/swipeable-flows.css';

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

function SwipeableFlows(props) {
    const [flows, setFlows] = useState(null);
    const [animateHeight, setAnimateHeight] = useState(false);

    useEffect(() => {
        setFlows(props.flows);
    }, [props.flows])

    useEffect(() => {
        // This is being done as a workaround to an issue in swipeable views, where if animateHeight true from the get go,
        // the first slide's height gets stuck at 0px
        if (props.animateHeight) {
            setTimeout(() => {
                setAnimateHeight(true); 
            }, 500);
        }
    }, [flows])

    return (
        flows ?
        <div style={{maxWidth: "49rem"}}>
            <BindKeyboardSwipeableViews enableMouseEvents={true}
            animateHeight={animateHeight}
            onChangeIndex={props.handleChangeIndex}
            >
                {flows.map((flow, index) =>
                <div key={flow.entry._id}>
                    <FlowentryDetailView key={flow.entry._id} flowEntry={flow.entry}
                        style={props.flowStyles && props.flowStyles[index]}
                        colorScheme={props.colorScheme}
                        highlightTag={props.highlightTag}/>
                    {index < flows.length - 1 ?
                    <p className='swipeHint'>Swipe or hit → key for next</p>
                    : <p className='swipeHint'>End</p>
                    }
                </div>
                )}
            </BindKeyboardSwipeableViews>
        </div>
        : <></>
    )
}

export default SwipeableFlows