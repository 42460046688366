import React, { useEffect, useState } from 'react';
import SwipeableFlows from '../bigPicture/SwipeableFlows';
import '../css/cause-details-modal.css';
import xIcon from '../../images/X.svg';
import { DARKMODE_FLOW_GRADIENTCOLORS, GRADIENT_DIRECTIONS } from '../../Colors';

function CauseDetails(props) {
    const [flowsForCause, setFlowsForCause] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [flowStyles, setFlowStyles] = useState();

    useEffect(() => {
        if (props.flows) {
            setFlowsForCause(props.flows.flowsForCause.sort((a, b) => a.date - b.date));
            const flowStyles = getFlowStyles(props.flows.flowsForCause);
            setFlowStyles(flowStyles);
        }
    }, [props.flows])

    const getFlowStyles = (flows) => {
        let flowStyles = [];
        flows.forEach((entry, index) => {
            const gradientIndex = index % GRADIENT_DIRECTIONS.length; // we loop through the gradient directions
            const gradientDirection = GRADIENT_DIRECTIONS[gradientIndex];
            const color1 = DARKMODE_FLOW_GRADIENTCOLORS[entry.color.name].Color1;
            const color2 = DARKMODE_FLOW_GRADIENTCOLORS[entry.color.name].Color2;
            const flowGradient = `linear-gradient(${gradientDirection}, ${color1}, ${color2})`

            flowStyles.push({
                margin: "0 5px",
                background: flowGradient
            })
        });

        return flowStyles;
    }

    const getHeading = (causeName) => {
        if (causeName?.length > 35 && causeName.toLowerCase().includes('something else')) {
            return 'Something else (...)'
        }
        return causeName;
    }

    const handleChangeIndex = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className='causeModal'>
            <div className='causeContainer'>
                <div className='causeStoriesProgressContainer'>
                    {flowsForCause &&
                        flowsForCause.map((element, index) => 
                            <div key={index} className='causeStoryBar'
                            style={index === currentIndex ? {"backgroundColor": "white"} : {}}>
                            </div>
                        )
                    }
                </div>
                <div className='causeHeadingContainer'>
                    <div>
                        <h2 className='causeHeading'>{getHeading(props.flows?.causeName)}</h2>
                    </div>
                    <header className='causeModalHeader'>
                        <button className="button-transparent" onClick={props.closeModal}>
                            <img src={xIcon} className="xCloseModal" alt="X" />
                        </button>
                    </header>
                </div>
                { flowsForCause && flowStyles &&
                <SwipeableFlows flows={flowsForCause} 
                    handleChangeIndex={handleChangeIndex}
                    flowStyles={flowStyles}
                    highlightTag={props.flows?.causeName}
                    colorScheme={'DARK'}
                    animateHeight={true}/>
                }
            </div>
        </div>
    )
}

export default CauseDetails;