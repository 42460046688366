import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { getFlowentryCardSummary, calculateEntryColor,
    getDateTimeFromId, getAbbreviatedDateFromId } from '../../helpers/EntriesHelper';
import '../css/flowentry_card.css';
import FlowentryCardTags from './FlowentryCardTags';

function FlowentryCard(props) {
    let history = useHistory();
    let route = useRouteMatch();

    const entryState = getFlowentryCardSummary(props.entry);
    const entryColors = calculateEntryColor(entryState);
    const cardColorStyle = {
        background : entryColors.BG
    }

    const handleClick = () => {
        history.push(`${route.url}/${props.entry._id}`, 
            {
                id: props.entry._id,
                entry: props.entry,
                entryColors: entryColors,
                entryState: entryState
            });
    }

    return (
        <div className='card-div' style={cardColorStyle} onClick={handleClick}>
            <div className='datetime-container'>
                <h4 className='entry-date'>
                    <span className='date-desktop'>{getDateTimeFromId(props.entry._id)}</span>
                    <span className='date-mobile'>{getAbbreviatedDateFromId(props.entry._id)}</span>
                </h4>
            </div>
            <FlowentryCardTags entryState={entryState} tagColor={entryColors.tag}/>
        </div>
    );
}

export default FlowentryCard;