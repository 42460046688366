import React, { useEffect, useState } from 'react';
import FlowentryCard from './FlowentryCard';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import '../css/checkins_list.css';

// This component simply takes a list of entries and displays them.
function CheckinsList(props) {
  const [sortedFlows, setSortedFlows] = useState();

  useEffect(() => {
    if (props.entries) {
      setSortedFlows(props.entries.sort((a, b) => a.date - b.date)); // ascending order
    }
  }, [props.entries])


  return (
    <TransitionGroup component={null}>
    <CSSTransition
    key={uuidv4()}
    exit={false} //Stops any exit animation classes from being applied
    timeout={500}
    classNames={props.clickState === 'next' ? 'slide-next' : 'slide-prev'}
    >
      <div className='checkins-list-container'>
          {sortedFlows &&
              sortedFlows.map((item) =>
              <FlowentryCard key={item.entry._id} entry={item.entry}/>
          )}
      </div>
    </CSSTransition>
    </TransitionGroup>
  )
}

export default CheckinsList;